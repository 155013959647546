import { Injectable } from '@angular/core';
import { Auth, authState, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private loggedInUser$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private auth: Auth) {
    authState(this.auth).subscribe((user) => {
      if (user && user.uid) {
        this.loggedInUser$.next(user);
      } else {
        this.loggedInUser$.next(null);
      }
    });
  }

  getLoggedInUser(): Observable<any> {
    return this.loggedInUser$.asObservable();
  }

  login(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  logOut() {
    return signOut(this.auth);
  }
}
