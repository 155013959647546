<main class="container">
  <header>
    <hgroup>
      <!-- <img src="/assets/images/am_logo_full_h.svg" alt="AccidentMATE" width="156px" /> -->
      <div>&nbsp;</div>
      <img src="/assets/images/om_logo_full_h.svg" alt="AccidentMATE" width="156px" />
      <h2>DEMO Administration</h2>
    </hgroup>
  </header>

  <article>
    <h3>Sign In</h3>
    <form [formGroup]="loginForm" (ngSubmit)="login()">
      <label for="email">
        Email
        <input
          type="email"
          name="email"
          formControlName="email"
          placeholder="Email address"
          aria-label="Email address"
          required
        />
      </label>
      <label for="password">
        Password
        <input
          type="password"
          name="password"
          placeholder="Password"
          formControlName="password"
          aria-label="Password"
          required
        />
      </label>
      <p class="warning" *ngIf="loginForm.dirty && loginForm.invalid">
        <small>Valid Email & Password required!</small>
      </p>
      <button type="submit" *ngIf="!showProgress" [disabled]="loginForm.invalid">Login</button>
      <button aria-busy="true" *ngIf="showProgress">Please wait…</button>
    </form>
  </article>
</main>
