import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup = new FormBuilder().group({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });
  showProgress = false;
  private userSub!: Subscription;

  constructor(private router: Router, private auth: AuthService) {}

  ngOnInit(): void {
    this.userSub = this.auth.getLoggedInUser().subscribe((user) => {
      if (user && user.uid) {
        this.router.navigate(['/app/dashboard']);
      }
    });
  }

  ngOnDestroy(): void {
    this.userSub?.unsubscribe();
  }

  login(): void {
    this.showProgress = true;
    console.log('Login:', this.loginForm.value);
    const { email, password } = this.loginForm.value;
    this.auth
      .login(email, password)
      .then(() => {
        this.router.navigate(['/app/dashboard']);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => (this.showProgress = false));
  }
}
